.app-logo {
  height: 18px;
  /* margin-left: 60px; */
}

/* .app-bar {
} */

.app-bar::after {
  height: 4px;
  position: absolute;
  top: 100%;
  right: 0px;
  left: 0px;
  background: linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px);
  content: "";
}

.active-indicator {
  width: 5px;
  height: 24px;
  background: #FFC107;
  position: absolute;
  left: 0;
  border-radius: 0px 5px 5px 0px;
}

.analytic-report-container {
  /* width: 100vw; */
  /* height: 100vh; */
  /* position: absolute; */
  height: calc(100% - 60px);
  ;
  width: 100%;
}

.free-badge {
  font-size: 0.7rem;
  /* background: #e91e63; */
  padding: 5px;
  position: absolute;
  right: 0;
  border-radius: 5px;
  color: #FFF;
}

.preview-image {
  width: 100%;
  filter: blur(8px) grayscale(100);
  -webkit-filter: blur(8px) grayscale(100%);
}

.preview-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legacy-root-container {
  background-color: #0b0c0d;
  height: 100vh;
  width: 100vw;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #FFF;
  align-items: center;
  overflow: hidden;
}

.legacy-loader {
  border: 3px solid #ffffff0f;
  border-top: 5px solid #e3af4a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.legacy-analytic-report-container {
  width: 100vw;
  height: 100vh;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}